<template>
    <div>
      <b-row>
        <b-col lg="12" md="12" sm="12" class="heading-title">
              <h4>Edit Child Venue</h4>
                  
              <b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
            <b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
            <b-breadcrumb-item to="/venues">Parent Venue</b-breadcrumb-item>
            <b-breadcrumb-item :to="'/venues/child-venue/' + this.parent_venue_id" >Child Venue</b-breadcrumb-item>
            <b-breadcrumb-item active>Edit <i class="mdi mdi-arrow-right"></i> {{v_name}} </b-breadcrumb-item>
        </b-breadcrumb>
      </b-col>
        <!-- <b-col lg="6" md="6" sm="6" class="btns-header"> </b-col> -->
      </b-row>
      <br />
      <!-- Tabs with card integration -->
      <b-card class="mb-4 w-100">
        <b-tabs v-model="tabIndex" content-class="mt-3" fill  class="custom-navbar" >
            <div class="flex justify-content-between">
        <div class=" mb-2">
        <!-- <b-button @click="tabIndex--" v-if="tabIndex!=0" class="btn-venuecolor btn-sm">Previous</b-button> -->
      </div>
      <!-- <div>
        <b-alert show size="sm">Please Select Time</b-alert>
      </div> -->
      <div class="mt-2 mb-2">
         
  
          <!-- <b-button @click="tabIndex++" v-if="tabIndex!=6" class="btn-venuecolor  ml-2 btn-sm" :disabled="tabIndex>1">Skip</b-button> -->
          <b-button @click="save_and_next" v-if="tabIndex!=6" class="btn-venuecolor ml-2 btn-sm" >Update </b-button>
         
          <b-button @click="save_and_next" v-if="tabIndex==6" class="btn-venuecolor btn-sm">Update</b-button>
       
       <!--  <div class="text-muted">Current Tab: {{ tabIndex }}</div> -->
      </div>
      </div>
          <b-tab  lazy>
            <template #title>
              <i class="mdi mdi-stackoverflow"></i> Overview
            </template>
            <Overview ref="OverView" @name="getName"/>
          </b-tab>
          <b-tab lazy>
            <template #title>
              <i class="mdi mdi-stackoverflow"   ></i> Time Shift
            </template>

            <TimeShift ref="Timeshift" :chld_venue_id='child_venue_id'/>
          </b-tab>
          <b-tab  :active="set_custom_amount_change==2" lazy>
            <template #title>
              <i class="mdi mdi-stackoverflow"></i> Price Shift
            </template>

            <Price_shift ref="Priceshift" :chld_venue_id='child_venue_id'/>
          </b-tab>
          <b-tab   >
            <template #title>
              <i class="mdi mdi-stackoverflow"></i> Capacity
            </template>
            <MoreDeatl ref="MoreDeatl" :chld_venue_id='child_venue_id' />
          </b-tab>
          <b-tab  >
            <template #title>
              <i class="mdi mdi-stackoverflow"></i> Gallery View
            </template>
            <Gallery ref="gallery" :chld_venue_id='child_venue_id' />
          </b-tab>
          <b-tab    >
            <template #title>
              <i class="mdi mdi-stackoverflow"></i> Amentities
            </template>
            <Amentities ref="amentities" :chld_venue_id='child_venue_id' />
            
          </b-tab>
          <b-tab >
            <template #title>
              <i class="mdi mdi-stackoverflow"></i> Add-On
            </template>
            <AddOns ref="addons" :chld_venue_id='child_venue_id' />
          </b-tab>
        </b-tabs>
      </b-card>
      <!-- Control buttons-->
    
      
    </div>
  </template>
  
  <script>
  //import TimeShift from '../../newVenue/new_venue_setting.vue'
  import Price_shift from './price_shift.vue'
  import TimeShift from './Time_shift.vue'
  import Overview from './overview.vue'
  import MoreDeatl from './More_det.vue'
  import Gallery from './Gallery.vue'
  import Amentities from './Amentities.vue'
  import AddOns from './addOns.vue'

  import { mapState } from "vuex";
  import axios from "axios";

    export default {
      data() {
        return {
          tabIndex:  0,
          child_venue_id:'',
          parent_venue_id:'',
          v_name:'',
          status:true,
        }
      },
      components: {
        Price_shift,
        TimeShift,
        Overview,
        MoreDeatl,
        Gallery,
        Amentities,
        AddOns,
      },
      methods:
      {
        save_and_next()
        {
          
          if(this.tabIndex==0)
          {
            this.$refs.OverView.next_overview();
            this.$root.$refs.app.showToast(
                "success",
                "Overview Updated successfully"
              );
              this.$refs.OverView.get_overview();
          }
          else if(this.tabIndex==1)
          {
            this.$root.$refs.app.showToast(
                "success",
                "Shift time Updated successfully"
              );
             this.$refs.Timeshift.newShiftUpdate()
          }
          else if(this.tabIndex==2)
          {
            this.$root.$refs.app.showToast(
                "success",
                "Shift Price Updated successfully"
              );
            this.$refs.Priceshift.nextvalidation()
            
          }
          else if(this.tabIndex==3)
          {
            this.$root.$refs.app.showToast(
                "success",
                "More Deatils Updated successfully"
              );
            this.$refs.MoreDeatl.next_moredetails()
           
          }
          else if(this.tabIndex==4)
          {
            this.$root.$refs.app.showToast(
                "success",
                "Gallery Updated successfully"
              );
           this.$refs.gallery.next_gallery_view()
          
          }
          else if(this.tabIndex==5)
          {
            this.$root.$refs.app.showToast(
                "success",
                "Amenities Updated successfully"
              );
             this.$refs.amentities.next_amenities()
            this.$refs.addons.add_on_Listing()
          
          }
          else if(this.tabIndex==6)
          {
              this.$refs.addons.nextvalidation()
              this.$root.$refs.app.showToast(
                "success",
                "Add-Ons Updated successfully"
              );
          }
         /*  if(obj==1)
          {
            return this.tabIndex++;
          } 
          else if(obj==2)
          {
            const id = this.$route.params.id;
            this.$router.push("/venues/child-venue/" + id, () => {
              this.$root.$refs.app.showToast(
                "success",
                "Child venue created successfully"
              );
            });
          } */
          
          
        },
        /* checkvalidation(num)
        {
          //return this.tabIndex > num ? false : true;
        }, */
        go_to_child() {
          const id = this.$route.params.id;
          this.$router.push("/venues/child-venue/" + id);
      },
      getName(value) {
      this.child_venue_id=value; // Raja Tamil
   },
   get_overview: function () {
    
    const tab_type = "overview";
    axios
      .post("/api/fetch_details", {
        tab_type: tab_type,
        child_venue_id: this.$route.params.id,
      })
     .then((resp) => {
       if (resp.data.status_code == 200) {
         if (resp.data.status) {
          
           this.parent_venue_id =
           resp.data.child_venue_details[0].parent_venue_id;
           this.v_name =
           resp.data.child_venue_details[0].child_venue_name;
          
         }
       }
     });
   },
  
      //set_custom_amount_change
    },
    mounted()
    {
      this.get_overview();
    },
    computed: {
    ...mapState(['set_custom_amount_change']),
   },
    }
  </script>
  <style>
  .custom-navbar .nav-link.active  {
        color: white !important;
        background-color: #499CE8 !important;
        border-bottom: 2px solid #DD1BFB !important;
        border-color: 0 !important;
        }
  </style>