<template>
  <div>

    <b-form>
              <div class="p-2">
                <div class="text-center">
                  <h6 class="custom-text"><span>Venue Details</span></h6>
                </div>

                <b-row>
                  <b-col md="6">
                    <b-row class="py-3 align-items-center">
                      <b-col sm="4" class="text-left">
                        <label
                          class="fw-medium mb-0 mt-2"
                          for="lname2"
                          style="font-size: 13px"
                          >Child Venue Name<span class="text-danger">*</span>
                          :</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <b-form-group>
                          <b-form-input class="venue-custom-input"
                            id="v_name"
                            type="text"
                            placeholder="Enter your child venue name"
                            v-model="$v.overview_form.v_name.$model"
                            :state="validateStateOverview('v_name')"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            id="v_name"
                            v-if="$v.overview_form.v_name.$error"
                          >
                            <div v-if="!$v.overview_form.v_name.required">
                              Please enter venue name
                            </div>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col md="6">
                    <b-row class="py-3 align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0 mt-2" for="nname2"
                          >Category<span class="text-danger">*</span> :</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <b-form-group>
                          <b-form-select class="venue-custom-input bac_none"
                            id="category"
                            v-model="$v.overview_form.category.$model"
                            :options="category_options"
                            :state="validateStateOverview('category')"
                          ></b-form-select>
                          <b-form-invalid-feedback
                            id="category"
                            v-if="$v.overview_form.category.$error"
                          >
                            <div v-if="!$v.overview_form.category.required">
                              Please select category
                            </div>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-row class="py-3 align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0 mt-2" for="lname2"
                          >Built year:</label
                        >
                      </b-col>
                      <b-col sm="8" class="built_year">
                        
                          <datepicker class="venue-custom-input bac_none"
                            minimum-view="year"
                            id="renovated_year"
                            format="yyyy"
                            placeholder="Enter built year"
                            v-model="overview_form.renovated"
                          >
                          </datepicker>
                      
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="6">
                    <b-row class="py-3 align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0 mt-2" for="uname2">
                          Guest Capacity:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <b-form-group>
                          <b-form-input class="venue-custom-input"
                            id="guest_room"
                            type="text"
                            placeholder="Enter guest Capacity"
                            v-model="overview_form.guest_room"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                 <!-- <b-col md="6">
                    <b-row class="py-3 align-items-center">
                      <b-col sm="5" class="text-left">
                        <label class="fw-medium mb-0 mt-2" for="uname2">
                          Metting Space:</label
                        >
                      </b-col>
                      <b-col sm="7">
                        <b-form-group>
                          <b-form-input class="venue-custom-input"
                            id="metting_space"
                            type="text"
                            placeholder="Enter metting space"
                            v-model="$v.overview_form.metting_space.$model"
                            :state="validateStateOverview('metting_space')"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            id="metting_space"
                            v-if="$v.overview_form.metting_space.$error"
                          >
                            <div v-if="!$v.overview_form.metting_space.numeric">
                              Please enter valid metting space
                            </div>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>-->
                  <b-col md="6">
                    <b-row class="py-3 align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Contact<span class="text-danger">*</span> :</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <b-form-group>
                          <b-form-input class="venue-custom-input"
                            placeholder="Enter venue contact"
                            v-model="$v.overview_form.contact.$model"
                            :state="validateStateOverview('contact')"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            id="contact"
                            v-if="$v.overview_form.contact.$error"
                          >
                            <div v-if="!$v.overview_form.contact.required">
                              Please enter venue contact
                            </div>
                            <div
                              v-if="
                                !$v.overview_form.contact.numeric ||
                                !$v.overview_form.contact.minLength ||
                                !$v.overview_form.contact.maxLength
                              "
                            >
                              Please valid enter venue contact
                            </div>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            
              </div>
             <!-- <h6 class="custom-text"><span>Location details</span></h6>
              <div class="p-2">
                
                <b-row>
                  <b-col md="6">
                    <b-row class="py-3 align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Pincode<span class="text-danger">*</span> :</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <b-form-group>
                          <b-form-input class="venue-custom-input"
                            name="pincode"
                            id="pincode"
                            placeholder="Enter venue pincode"
                            v-model="$v.overview_form.pincode.$model"
                            :state="validateStateOverview('pincode')"
                            @keyup="pincodeCheck()"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            id="pincode"
                            v-if="$v.overview_form.pincode.$error"
                          >
                            <div v-if="!$v.overview_form.pincode.required">
                              Please enter venue pincode
                            </div>
                            <div
                              v-if="
                                !$v.overview_form.pincode.numeric ||
                                !$v.overview_form.pincode.minLength ||
                                !$v.overview_form.pincode.maxLength
                              "
                            >
                              Please valid enter venue pincode
                            </div>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="6">
                    <b-row class="py-3 align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0 mt-2" for="nname2"
                          >Location<span class="text-danger">*</span> :</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <b-form-group>
                          <b-form-input class="venue-custom-input"
                            id="v_locations"
                            type="text"
                            v-model="overview_form.location"
                            placeholder="Enter venue location"
                           
                          ></b-form-input>
                        </b-form-group>
                    
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-row class="py-3 align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >City<span class="text-danger">*</span> :</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <b-form-group>
                          <b-form-input class="venue-custom-input"
                            name="city"
                            id="city"
                            type="text"
                            placeholder="Enter venue city"
                            v-model="$v.overview_form.city.$model"
                            :state="validateStateOverview('city')"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            id="city"
                            v-if="$v.overview_form.city.$error"
                          >
                            <div v-if="!$v.overview_form.city.required">
                              Please enter venue city
                            </div>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                
                </b-row>
              </div> -->
              <h6 class="custom-text"><span>More details</span></h6>
              <div class="p-2">
             <!--    <div class="text-center">
                  <h4 class="">
                    <u>More Details</u>
                  </h4>
                </div> -->

                <!-- <b-row>
                  <b-col md="12">
                    <b-row class="py-3 align-items-center">
                      <b-col sm="2" class="text-left">
                        <label class="fw-medium mb-0 mt-2" for="uname2">
                          Distance From Airport:</label
                        >
                      </b-col>
                      <b-col sm="10">
                        <b-form-group>
                          <b-form-input  class="venue-custom-input"
                            id="dist_airport"
                            type="text"
                            placeholder="Enter distance from airport"
                            v-model="overview_form.dist_airport"
                          >
                          </b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-row class="py-3 align-items-center">
                      <b-col sm="2" class="text-left mt-2">
                        <label class="fw-medium mb-0" for="uname2">
                          NearBy Attraction :</label
                        >
                      </b-col>
                      <b-col sm="10">
                        <b-form-group>
                          <b-form-textarea class="venue-custom-input"
                            id="near_by"
                            placeholder="Enter nearby attration"
                            v-model="overview_form.near_by"
                          ></b-form-textarea>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row> -->
                <b-row>
                  <b-col md="6">
                    <label class="fw-medium mb-0 mt-2" for="uname2">
                      About:</label
                        >
                       <!--  <b-form-textarea  class="venue-custom-input"
                            id="details_bio"
                            placeholder="Enter The more Details"
                            v-model="overview_form.details_bio"
                            @focus="aboutfocus"
                            v-if="aboutview!=true"
                          ></b-form-textarea> -->
                          
                    <vue-editor v-model="overview_form.details_bio" :editor-toolbar="customToolbar" class="venue-custom-input"></vue-editor>
                    <!--<b-row class="py-3 align-items-center">
                      <b-col sm="2" class="text-left">
                        <label class="fw-medium mb-0 mt-2" for="uname2">
                          More :</label
                        >
                      </b-col>
                      <b-col sm="10">
                        <b-form-group>
                          <b-form-textarea  class="venue-custom-input"
                            id="details_bio"
                            placeholder="Enter The more Details"
                            v-model="overview_form.details_bio"
                          ></b-form-textarea>
                        </b-form-group>
                      </b-col>
                    </b-row>-->
                  </b-col>
               
                 
                  
                  <b-col md="6">
                    <label class="fw-medium mb-0 mt-2" for="uname2">
                      Cancellation Policy:</label
                        >
                    <vue-editor v-model="overview_form.cancellation_policy" :editor-toolbar="customToolbar" class="venue-custom-input"></vue-editor>
                   <!-- <b-row class="py-3 align-items-center">
                      <b-col sm="2" class="text-left">
                        <label class="fw-medium mb-0 mt-2" for="uname2">
                          Cancellation Policy :</label
                        >
                      </b-col>
                      <b-col sm="10">
                        <b-form-group>
                          <b-form-textarea  class="venue-custom-input"
                            id="cancellation_policy"
                            type="text"
                            placeholder="Enter Cancellation Policy"
                            v-model="overview_form.cancellation_policy"
                          >
                          </b-form-textarea>
                        </b-form-group>
                      </b-col>
                    </b-row>-->
                  </b-col>
                  <b-col md="6">
                    <label class="fw-medium mb-0 mt-2" for="uname2">
                      Facility Restrictions:</label
                        >
                    <vue-editor v-model="overview_form.facility_restrictions" :editor-toolbar="customToolbar" class="venue-custom-input"></vue-editor>
                   <!-- <b-row class="py-3 align-items-center">
                      <b-col sm="2" class="text-left">
                        <label class="fw-medium mb-0 mt-2" for="uname2">
                          Facility Restrictions :</label
                        >
                      </b-col>
                      <b-col sm="10">
                        <b-form-group>
                          <b-form-textarea  class="venue-custom-input"
                            id="cancellation_policy"
                            type="text"
                            placeholder="Enter Facility Restrictions"
                            v-model="overview_form.facility_restrictions"
                          >
                          </b-form-textarea>
                        </b-form-group>
                      </b-col>
                    </b-row>-->
                  </b-col>
                  <b-col md="6">
                    <label class="fw-medium mb-0 mt-2" for="uname2">
                          Additional Information:</label
                        >
                    <vue-editor v-model="overview_form.more_info" :editor-toolbar="customToolbar" class="venue-custom-input"></vue-editor>

                   <!-- <b-row class="py-3 align-items-center">
                      <b-col sm="2" class="text-left">
                        <label class="fw-medium mb-0 mt-2" for="uname2">
                          Additional Information:</label
                        >
                      </b-col>
                      <b-col sm="10">
                        
                         <b-form-group>
                          <b-form-textarea class="venue-custom-input"
                            id="more_info"
                            type="text"
                            placeholder="Enter Additional Information"
                            v-model="overview_form.more_info"
                          >
                          </b-form-textarea>
                        </b-form-group> 
                      </b-col>
                    </b-row>-->
                  </b-col>

                </b-row>
              </div>
            </b-form>
  </div>
</template>

<script>
//import Multiselect from "vue-multiselect";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import { validationMixin } from "vuelidate";
import moment from 'moment';
import {
  required,
  minLength,
  maxLength,
  numeric,
  //requiredIf
} from "vuelidate/lib/validators";

import { VueEditor } from "vue2-editor";
export default {
    name: "Overview",
    mixins: [validationMixin],
    data: () => ({
    title: "child_venue_add",
    props: [ 'child_venue_id' ],
    category_options: [{ value: null, text: "Please select category" }],
        
    overview_form: {
      v_name: "",
      //  v_location: "",
      city: "",
      contact: "",
      category: null,
      wing: "",
      metting_space: "",
      renovated: "",
      guest_room: "",
      dist_airport: "",
      near_by: "",
      details_bio: "",
      more_info: "",
      pincode: "",
      cancellation_policy: "",
      facility_restrictions: "",
      address: "",
    },
    aboutview:false,
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["image", "code-block"]
    ]
    }),
    components: {
    
  //  Multiselect,
    Datepicker,
    VueEditor
  },
  validations: {
    overview_form: {
      v_name: {
        required,
      },
     /*  city: {
        required,
      }, */
      contact: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      category: {
        required,
      },
     /*  pincode: {
        required,
        numeric,
        minLength: minLength(6),
        maxLength: maxLength(6),
      } */
    },
  },
  methods:
  {
    validateStateOverview(v_name) {
      const { $dirty, $error } = this.$v.overview_form[v_name];
      return $dirty ? !$error : null;
    },
    save_child_venue: function () {
      const id = this.$route.params.id;
      this.$router.push("/venues/child-venue/" + id, () => {
        this.$root.$refs.app.showToast(
          "success",
          "Child venue created successfully"
        );
      });
    },
    overview_category_list() {
      axios.post("/api/fetch_categories").then((resp) => {
        if (resp.data.status_code == 200) {
          if (resp.data.status) {
            for (var i = 0; i < resp.data.venue_categories.length; i++) {
              this.category_options.push({
                value: resp.data.venue_categories[i].id,
                text: resp.data.venue_categories[i].name,
              });
            }
          }
        }
      });
    },
    next_overview: function () {
        this.$v.overview_form.$touch();
        if (!this.$v.overview_form.$anyError) {
          if (this.overview_form.renovated != "")
            this.overview_form.renovated = moment(
              this.overview_form.renovated,
              "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
            ).format("yyyy");
          const tab_type = "overview";
         
          const tabs = JSON.parse(
            '{"overview":1, "shift": 0, "more_detais":0, "gallery":0 , "amenities":0, "add_on":0}'
          );
          axios
            .post("/api/add_child_venue", {
              tab_type: tab_type,
              child_venue_id: this.child_venue_id,
              child_venue_name: this.overview_form.v_name,
              // child_venue_location: this.overview_form.v_location,
              child_venue_city: this.overview_form.city,
              child_venue_pincode: this.overview_form.pincode,
              child_venue_contact: this.overview_form.contact,
              venue_category_id: this.overview_form.category,
              parent_venue_id: this.parent_venue_id,
              wing: this.overview_form.wing,
              renovated: this.overview_form.renovated,
              guest_rooms: this.overview_form.guest_room,
              total_meeting_space: this.overview_form.metting_space,
              distance_from_airport: this.overview_form.dist_airport,
             // more_info: this.overview_form.more_info,
              //child_venue_details: this.overview_form.details_bio,
              nearby_attraction: this.overview_form.near_by,
              filled_tab: tabs,
              place_name: '',
              place_formatted_address: '',
              city: this.overview_form.city,
              state: this.overview_form.state,
              country: 'india',
              pin: this.overview_form.pincode,
              latitude: '',
              longitude: '',
              place_id: this.overview_form.city,
              cancellation: this.overview_form.cancellation_policy,
              facility: this.overview_form.facility_restrictions,
              more: this.overview_form.details_bio,
              aditional: this.overview_form.more_info,
              address: this.overview_form.address,
            })
            .then((resp) => {
              if (resp.data.status_code == 200) {
                if (resp.data.status) {
                  this.child_venue_id = resp.data.child_venue_id;
                  this.child_venue_ids = resp.data.child_venue_id;
                  this.$v.overview_form.$reset();
                  localStorage.setItem('child_venue_id', resp.data.child_venue_id); //temporary
                  localStorage.setItem('create_type', 'New'); //temporary
                 
                  this.$emit('name', resp.data.child_venue_id);
                  
                } else {
                  this.$root.$refs.app.showToast("danger", resp.data.message);
                  
                }
                
              }
            });
            return 1;
        } 
        else
        {
            return 0;
        }
    },
    aboutfocus()
    {
        return true;
    },
    get_overview: function () {
     
				this.child_venue_id = this.$route.params.id;
				const tab_type = "overview";
				axios
				.post("/api/fetch_details", {
					tab_type: tab_type,
					child_venue_id: this.child_venue_id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
             
							this.parent_venue_id =
							resp.data.child_venue_details[0].parent_venue_id;
							this.overview_form.v_name =
							resp.data.child_venue_details[0].child_venue_name;
							this.overview_form.pincode =
							resp.data.child_venue_details[0].child_venue_pincode;
							this.overview_form.city =
							resp.data.child_venue_details[0].child_venue_city;
							this.overview_form.contact =
							resp.data.child_venue_details[0].child_venue_contact;
							this.overview_form.category =
							resp.data.child_venue_details[0].venue_category_id;
							this.overview_form.wing = resp.data.child_venue_details[0].wing;
							this.overview_form.renovated =
							resp.data.child_venue_details[0].renovated;
							this.overview_form.guest_room =
							resp.data.child_venue_details[0].guest_rooms;
							this.overview_form.metting_space =
							resp.data.child_venue_details[0].total_meeting_space;
							this.overview_form.dist_airport =
							resp.data.child_venue_details[0].distance_from_airport;
							this.overview_form.more_info =
							resp.data.child_venue_details[0].more_info;
							this.overview_form.details_bio =
							resp.data.child_venue_details[0].child_venue_details;
							this.overview_form.near_by =
							resp.data.child_venue_details[0].nearby_attraction;
							this.overview_form.location =
							resp.data.child_venue_details[0].child_venue_city; 
							this.overview_form.v_location =
							resp.data.child_venue_details[0].child_venue_city;
							
							this.overview_form.cancellation_policy =
							resp.data.child_venue_details[0].cancellation;
							
							this.overview_form.facility_restrictions =
							resp.data.child_venue_details[0].aditional_info;
							
							this.overview_form.address =
							resp.data.child_venue_details[0].address;
              this.$emit('name',[resp.data.child_venue_details[0].parent_venue_id,resp.data.child_venue_details[0].child_venue_name]);
							/*   place_formatted_address =
							resp.data.child_venue_details[0].place_formatted_address; */
							/* document.getElementById("v_location").value =
								resp.data.child_venue_details[0].place_name;
							this.IsplaceChange = false; */
							// document.getElementById("v_location").focus();
							/* place_name = resp.data.child_venue_details[0].place_name;
								state = resp.data.child_venue_details[0].child_venue_state;
								city = resp.data.child_venue_details[0].child_venue_city;
								country = resp.data.child_venue_details[0].child_venue_country;
								pin = resp.data.child_venue_details[0].child_venue_pincode;
								latitude = resp.data.child_venue_details[0].latitude;
								longitude = resp.data.child_venue_details[0].longitude;
							place_id = resp.data.child_venue_details[0].place_id; */
							} else {
							// const parent_id = this.$route.params.id;
							this.$router.push("/venues/");
						}
					}
				});
			},
  },
  mounted()
  {
    this.get_overview();
    this.overview_category_list();
   // this.save_and_next();
  },
 
  //save_and_next

}
</script>

<style>

</style>