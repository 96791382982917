<template>
	<div>
    
		<b-row>
			<b-col md="4" sm="12" >
				<div class="card-" >
					<div class="card-header custom-card-header fixed">
						<div class="d-flex justify-content-between mb-2">
							<div> 
								<!-- {{ mshift_name ? mshift_name :"Morning Shift"  }} -->
								Morning Shift
							</div>
							<div>
								<b-form-checkbox v-model="card_enabled" name="check-button" switch >
							Activate
						</b-form-checkbox>
							</div>
						</div>
					</div>
					<div class="card-body " >
						<!-- <div> 
							<input type="text" class="popover-valid-custom-time-picker mb-2" v-model="mshift_name" placeholder="Enter Shift name" >
						</div> -->
                        <div class="d-flex justify-content-between ">
							<div> 
								<input type="time" :class="{[isValidTime()== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }" :state="isValidTime()" v-model="mst"  >
							</div>
							<div>TO</div>
							<div> 
								<input type="time" :class="{[EndisValidTime()== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }" :state="EndisValidTime()" v-model="met"  >
							</div>
							
						</div>
						
					</div>
				</div>
			</b-col>		
			<b-col md="4" sm="12" >
				<div class="card" >
					<div class="card-header custom-card-header fixed">
						<div class="d-flex justify-content-between mb-2">
							<div> 
								<!-- {{ ashift_name ? ashift_name :"Afternoon Shift"  }} -->
								Afternoon Shift
							</div>
							<div>
								<b-form-checkbox v-model="card_enabled1" name="check-button" switch >
							Activate
						</b-form-checkbox>
							</div>
						</div>
					</div>
					<div class="card-body " >
                        <div class="d-flex justify-content-between ">
							<div> 
								<input type="time" :class="{[isValidTime1('afternoon',card_enabled==true ? met : '23:59',ast,'L')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }"  v-model="ast" :state="isValidTime1('afternoon',card_enabled==true ? met : '23:59',ast,'L')" >
								
							</div>
							<div>TO</div>
							<div> 
								<input type="time" :class="{[EndisValidTime1('afternoon',aet,ast,'R')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }" v-model="aet" :state="EndisValidTime1('afternoon',aet,ast,'R')" >
								
							</div>
							
						</div>
						
					</div>
				</div>
			</b-col>
			<b-col md="4" sm="12" >
				<div class="card" >
					<div class="card-header custom-card-header fixed">
						<div class="d-flex justify-content-between mb-2">
							<div> 
							<!-- 	{{ eshift_name ? eshift_name :"Evening Shift"  }} -->
							Evening Shift
							</div>
							<div>
								<b-form-checkbox v-model="card_enabled2" name="check-button" switch>
							Activate  
						</b-form-checkbox>
							</div>
						</div>
					</div>
					<div class="card-body " >
                        <div class="d-flex justify-content-between ">
							<div> 
								<input type="time" 	:class="{[isValidTime2('evening',est,card_enabled1==true ? aet : '00:00:00','L')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }" v-model="est" :state="isValidTime2('evening',est,card_enabled1==true ? aet : '00:00:00','L')">
								
							</div>
							<div>TO</div>
							<div> 
								<input type="time" :class="{[EndisValidTime2('evening',eet,est,'R')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }" v-model="eet" :state="EndisValidTime2('evening',eet,est,'R')">
								
							</div>
							
						</div>
						
					</div>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
	import moment from "moment";
	import axios from "axios";
	export default {
        props:['chld_venue_id'],
		data() {
			return {
				mshift_name:'Morning',
				ashift_name:'Afternoon',
				eshift_name:'Evening',
				met:"00:00:00",
				mst:"00:00:00",
				msd:"",
				med:"",
				msa:"",
				ast:"00:00:00",
				aet:"00:00:00",
				asd:"",
				aed:"",
				aea:"",
				est:"00:00:00",
				eet:"00:00:00",
				esd:"",
				eed:"",
				eea:"",
				formatted: '',
				format : 'hh:mm:ss',
				current_year:new Date().getFullYear(),
				morningsave:false,
				afternoonsave:false,
				eveningsave:false,
				headers:[],
				gallerydesc:{},
				chld_venue_id1:{},
				card_enabled:false,
				card_enabled1:false,
				card_enabled2:false,
			}
		},
		
		methods:
		{
			timevalidation(shift)
			{
				if(shift=='Morning')
				{
					if(this.card_enabled2==true )
					{
						return this.eet;
					}
					else if(this.card_enabled1==true )
					{
						return this.aet;
					}
					else
					{
						return this.aet;
					}
				}
			},
			isValidTime() 
			{
				if(this.card_enabled==true )
				{
					//const endTime = this.timevalidation('Morning');
					const time = moment(this.mst, this.format);
					const beforeTime = moment('00:00:00', this.format);
					const afterTime = moment('23:59:00', this.format);
					
					if (time.isBetween(beforeTime, afterTime))
					{
						
						return true;
						
					} 
					else 
					{
						return false;
					} 
				}
				
				
				
			},
			EndisValidTime() 
			{
				if(this.card_enabled==true)
				{
				const time = moment(this.met, this.format);
				const beforeTime = moment(this.mst, this.format);
				const afterTime = moment('23:59:00', this.format);
				
				if (time.isBetween(beforeTime, afterTime))
				{
					
					return true;
					
				} 
				else 
				{
					return false;
				} 
			}
				
			},
			isValidTime1(checkshift,fromtime,totime,side) 
			{
				side
				if(this.card_enabled1==true)
				{
				const last_time = this.met ? this.met : '00:00:00';
				const time = moment(this.ast, this.format);
				const beforeTime = moment(last_time, this.format);
				const afterTime = moment('23:59:00', this.format);
				
				if (time.isBetween(beforeTime, afterTime))
				{
					
					return true;
					
				} 
				else 
				{
					return false;
				} 

				/* var beforeTime;
				var afterTime;
				var time;
				if(this.card_enabled==true && side=="L")
				{
					beforeTime = moment(this.met, "HH:mm");
					afterTime = moment('23:59', "HH:mm");
					time= moment(totime, "HH:mm");
				}
				else if(side=="L")
				{
					beforeTime = moment(totime, "HH:mm");
					afterTime = moment('23:59', "HH:mm");
					time= moment(totime, "HH:mm");
					//console.log('Moring Dibled Proper condiiton');  //proper
				}
				if(side=="R")
				{
					beforeTime = moment(this.ast, "HH:mm");
					afterTime = moment('23:59', "HH:mm");
					time= moment(this.aet, "HH:mm");
					console.log('Right side Enabled Proper condiiton');
				}
				
				
				
				
				
				
				
				if(time.isSameOrAfter(beforeTime) && time.isSameOrBefore(afterTime))
				{
					return true;
				}
				 */
			}
				
			},
			EndisValidTime1(checkshift,fromtime,totime,side) 
			{
				side
				if(this.card_enabled1==true)
				{
				const time = moment(this.aet, this.format);
				const beforeTime = moment(this.ast, this.format);
				const afterTime = moment('23:59:00', this.format);
				
				if (time.isBetween(beforeTime, afterTime))
				{
					
					return true;
					
				} 
				else 
				{
					return false;
				} 
			}
			},
			isValidTime2(checkshift,fromtime,totime,side) 
			{
side;
if(this.card_enabled2==true)
{
				const last_time = this.aet ? this.aet : '00:00:00';
				const time = moment(this.est, this.format);
				const beforeTime = moment(last_time, this.format);
				const afterTime = moment('23:59:00', this.format);
				
				if (time.isBetween(beforeTime, afterTime))
				{
					
					return true;
					
				} 
				else 
				{
					return false;
				} 				
				/* var beforeTime;
				var afterTime;
				var time;
				if(this.card_enabled==true && this.card_enabled1==true && side=="L")
				{
					beforeTime = moment(this.aet, "HH:mm"); //proper
					//	console.log('Moring Enabled Proper condiiton');
					afterTime = moment('23:59', "HH:mm");
					time= moment(this.est, "HH:mm");
				}
				else if(this.card_enabled==true && this.card_enabled1==false && side=="L")
				{
					beforeTime = moment(this.met, "HH:mm"); //proper
					//	console.log('Moring Enabled Proper condiiton');
					afterTime = moment('23:59', "HH:mm");
					time= moment(this.est, "HH:mm");
				}
				else if(this.card_enabled==false && this.card_enabled1==true && side=="L")
				{
					beforeTime = moment(this.aet, "HH:mm"); //proper
					//	console.log('Moring Enabled Proper condiiton');
					afterTime = moment('23:59', "HH:mm");
					time= moment(this.est, "HH:mm");
				}
				else if(side=="L")
				{
					beforeTime = moment(totime, "HH:mm");
					afterTime = moment(fromtime, "HH:mm");
					time= moment(totime, "HH:mm");
					//console.log('Moring Dibled Proper condiiton');  //proper
				}
				if(side=="R")
				{
					beforeTime = moment(this.est, "HH:mm");
					afterTime = moment('23:59', "HH:mm");
					time= moment(this.eet, "HH:mm");
					console.log('Right side Enabled Proper condiiton');
				}
				
				
				if(time.isSameOrAfter(beforeTime) && time.isSameOrBefore(afterTime))
				{
					return true;
				} */
				
}	
				
			}, 
			EndisValidTime2(checkshift,fromtime,totime,side) 
			{
				side
				if(this.card_enabled2==true)
				{

				const time = moment(this.eet, this.format);
				const beforeTime = moment(this.est, this.format);
				const afterTime = moment('23:59:00', this.format);
				
				if (time.isBetween(beforeTime, afterTime))
				{
					
					return true;
					
				} 
				else 
				{
					return false;
				} 
			}
			},
			get_toggle_data(checkshift,status)
			{
				axios.post("/api/Enable_public",{shift:checkshift,status:status,vid:this.chld_venue_id1 }).then((resp) => {
					resp;
					this.year_calender();
					this.allHeader();
				});
			},
			today_date()
			{
				var myDate = new Date();
				var d = new Date(myDate),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();
				
				if (month.length < 2) 
				month = '0' + month;
				if (day.length < 2) 
				day = '0' + day;
				return [year,month,day].join('-');
			},
			checkvalidation(a,b,c)
			{
				return [c,b,a].join('-');
				
			},
			get_data(shift)
			{
                //alert(this.chld_venue_id1)
               // this.child_venue_id = props.child_venue_id;
				if(shift=='Morning')
				{
					this.morningsave=true;
					
					if(this.mst =="" || this.met =="")
					{
						//this.$root.$refs.app.showToast("danger", 'Please select Time');
						
						//this.card_enabled='false';
						return  1;
					}
					if(this.card_enabled==true)
					{    //Same time			 //morning afternoon
                        if(this.mst==this.met)
						{
							//this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							
							return 1;
						}
					}
					if(this.card_enabled1==true)
					{
						if(this.mst==this.ast || this.mst==this.aet || this.met==this.ast || this.met==this.aet   )
						{
							//this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return 1;
						}
					}
					if(this.card_enabled2==true)
					{
						if(this.mst==this.est || this.mst==this.eet || this.met==this.est || this.met==this.eet   )
						{
							//this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return 1;
						}
					}
					
					axios.post("/api/calender_time_update",{shift_name:this.mshift_name,shift:shift,time:this.mst,beforeTime:this.met,status:this.card_enabled,vid:this.chld_venue_id1 }).then((resp) => {
						resp;
						//	this.$root.$refs.app.showToast("success", 'Morning Time Updated');
						
						
					});
					
				}
				else if(shift=='Afternoon')
				{
					this.afternoonsave=true;
					if(this.ast =="" || this.aet =="")
					{
						//this.card_enabled1=false;
						//this.$root.$refs.app.showToast("danger", 'Please select Time');
						return  1;
					}
					if(this.card_enabled1==true)
					{    //Same time			 //morning afternoon
                        if(this.ast==this.aet)
						{//
							//this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return 1;
						}
					}
					if(this.card_enabled==true)
					{
						if(this.ast==this.mst || this.ast==this.met || this.aet==this.mst || this.aet==this.met   )
						{
							//this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return 1;
						}
					}
					if(this.card_enabled2==true)
					{
						if(this.ast==this.est || this.ast==this.eet || this.aet==this.est || this.aet==this.eet   )
						{
							//this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return 1;
						}
					}
					axios.post("/api/calender_time_update",{shift_name:this.ashift_name,shift:shift,time:this.ast,beforeTime:this.aet,status:this.card_enabled1,vid:this.chld_venue_id1 }).then((resp) => {
						resp;
						//this.$root.$refs.app.showToast("success", 'Afternoon Time Updated');
						
						
					});
				}
				else if(shift=='Evening')
				{
					this.eveningsave=true
					if(this.est =="" || this.eet =="")
					{
						//this.$root.$refs.app.showToast("danger", 'Please select Time');
						//this.card_enabled2='false';
						return  1;
						
					}
					if(this.card_enabled3==true)
					{    //Same time			 //morning afternoon
                        if(this.est==this.eet)
						{
							//this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return 1;
						}
					}
					if(this.card_enabled==true)
					{
						if(this.est==this.mst || this.est==this.met || this.eet==this.mst || this.eet==this.met   )
						{
							//this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return 1;
						}
					}
					if(this.card_enabled1==true)
					{
						if(this.est==this.ast || this.est==this.aet || this.eet==this.ast || this.eet==this.aet   )
						{
							//this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return 1;
						}
					}
					axios.post("/api/calender_time_update",{shift_name:this.eshift_name,shift:shift,time:this.est,beforeTime:this.eet,status:this.card_enabled2,vid:this.chld_venue_id1 }).then((resp) => {
						resp;
						//this.$root.$refs.app.showToast("success", 'Evening Time Updated');
						
						
					});
				}
               // return 1;
				
			},
			allHeader()
			{
				axios.post("/api/all_publish_header",{vid:this.chld_venue_id1} ).then((resp) => {
					this.headers=resp.data.data;
					
				});
			},
			newShiftUpdate()
			{
				//alert(this.isValidTime())
				this.checkparent='no';
				this.get_data('Morning');
				this.get_data('Afternoon');
				this.get_data('Evening');
				this.checkparent='yes';
				if(this.card_enabled==true || this.card_enabled1==true || this.card_enabled2==true)
				{
					if(this.isValidTime()==true && this.EndisValidTime==true )
					{
						//this.get_data('Morning');
						return 1;
					}
					else
					{
						return 0;
					}
				}
				
				
			},
            check_shift_header()
			{
				this.chld_venue_id1 = this.$route.params.id;
				axios.get("/api/check_shift_header?vid="+this.chld_venue_id1).then((resp) => {
					
					var morning=resp.data.morning
					var afternoon=resp.data.afternoon
					var evening=resp.data.evening
					
					this.mshift_name = morning ? morning.custom_name : "Morning";
					this.mst = morning ? morning.from_time : "";
					this.met = morning ? morning.to_time : "";
					this.card_enabled =  morning ? morning.publish==1 ? true : false : false;
					
					
					
					this.ashift_name = afternoon ? afternoon.custom_name : "Afternoon";
					this.ast = afternoon ? afternoon.from_time : "";
					this.aet = afternoon ? afternoon.to_time : "";
					this.card_enabled1 =  afternoon ? afternoon.publish==1 ? true : false : false;
					
					this.eshift_name = evening ? evening.custom_name : "Evening";
					this.est = evening ? evening.from_time : "";
					this.eet = evening ? evening.to_time : "";
					this.card_enabled2 =  evening ? evening.publish==1 ? true : false : false;
					
					
				});
			},  
			
			doSomething() {
				this.checkparent="nono";
				
			}
		},
		mounted()
		{
			this.check_shift_header();
			this.chld_venue_id1 =this.$route.params.id
		},
        watch: {
    chld_venue_id: {
      immediate: true,
      handler (newVal) {
        this.chld_venue_id1 = newVal;
      }
    },
}
       
	}
</script>

<style>
	
</style>