<template>

	<div>
		
		<div class="event-tab">
			<b-button size="sm" variant="primary" @click="add_gallary()"
			class="float-right mt-5">Add Images/videos
			</b-button>
			<b-tabs  pills fill class="event-tab">
				<b-tab title="Thumbnail" active class="mt-3">
					<b-row>
						<b-col cols="3" v-for="(gallary_view_days, index) in gallary_view_day"
						:key="index">
							<div class="product-box">
								
								<b-link  :id="`popover-reactive-${index}`"
								class="edit-btn">
									<i class="mdi mdi-pencil"></i>
								</b-link>
								<b-link
								class="delete-btn"
								@click="remove_images('day_light', index)"
								>
									<i class="mdi mdi-delete-circle"></i>
								</b-link>
								<div class="product-image">
									<video
									v-if="
									gallary_view_days.file_extension === 'mp4' ||
									gallary_view_days.file_extension === 'mkv'
									"
									class="embed-responsive-item"
									controls
									:src="gallary_view_days.attachment"
									:type="'video/' + gallary_view_days.file_extension"
									></video>
									<img
									:src="gallary_view_days.attachment"
									v-if="
									gallary_view_days.file_extension === 'png' ||
									gallary_view_days.file_extension === 'jpeg' ||
									gallary_view_days.file_extension === 'jpg' ||
									gallary_view_days.file_extension === 'svg'
									"
									/>
								</div>
								<div class="img-overlay">
									
									<div class="text"> <!-- <textarea  v-model="gallerydesc[index]" >gallary_view_days.description</textarea> -->
									{{ gallary_view_days.description }}
										
									</div>
                  <b-popover 
							:target="`popover-reactive-${index}`"
							triggers="click"
							placement="bottom"
							ref="popover"
							id="my-trigger-button-id"
							no-fade
							>
								<template #title>
									<b-button class="close" aria-label="Close" @click="onClose3()">
										<span class="d-inline-block" aria-hidden="true">&times;</span>
									</b-button>
									Gallery Text update
								</template>
								
							<div> 
							<textarea :id="`thambanail-${index}`" class="form-control venue-custom-input" :value="gallary_view_days.description" ></textarea>
							</div>
							<div class="mt-3">
									
								<b-button @click="onClose3()" size="sm" variant="danger" class="mr-2">Cancel</b-button>
									<b-button  size="sm" variant="primary"  @click="saveGallerydesc($event,gallary_view_days.id, 'thambanail',index)">
									Save</b-button>
								</div>
							</b-popover>


								<!-- 	<button class="btn btn-sm btn-primary btn-extra" @click="saveGallerydesc(gallary_view_days.id,index)">Save</button> -->
								</div>
							</div>
						</b-col>
					</b-row>
					
					
					
					
					<!-- <carousel
						v-if="gallary_view_day && gallary_view_day.length"
						class="slider_event"
						:nav="true"
						:items="3"
						:dots="false"
						:mouseDrag="true"
						:touchDrag="true"
						:smartSpeed="800"
						:margin="10"
						:responsive="{
						0: { items: 1 },
						575: { items: 2 },
						991: { items: 3 },
						1199: { items: 3 },
						}"
						:key="day_light"
						>
						<div
						v-for="(gallary_view_days, index) in gallary_view_day"
						:key="index"
						>
						<div class="product-box">
						<b-link
						class="delete-btn"
						@click="remove_images('day_light', index)"
						>
						<i class="mdi mdi-delete-circle"></i>
						</b-link>
						<div class="product-image">
						<video
						v-if="
						gallary_view_days.file_extension === 'mp4' ||
						gallary_view_days.file_extension === 'mkv'
						"
						class="embed-responsive-item"
						controls
						:src="gallary_view_days.attachment"
						:type="'video/' + gallary_view_days.file_extension"
						></video>
						<img
						:src="gallary_view_days.attachment"
						v-if="
						gallary_view_days.file_extension === 'png' ||
						gallary_view_days.file_extension === 'jpeg' ||
						gallary_view_days.file_extension === 'jpg' ||
						gallary_view_days.file_extension === 'svg'
						"
						/>
						</div>
						</div>
						</div>
					</carousel> -->
					<div class="no-data" v-if="gallary_view_day.length === 0">
						<p>No images and video are here.</p>
					</div>
				</b-tab>
				<b-tab title="Banner" class="mt-3">
					<b-row>
						<b-col cols="3" v-for="(gallary_view_nights, index) in gallary_view_night"
						:key="index">
							<div class="product-box">
								<b-link  :id="`popover-reactive1-${index}`"
								class="edit-btn">
									<i class="mdi mdi-pencil"></i>
								</b-link>
								<b-link
								class="delete-btn"
								@click="remove_images('day_night', index)"
								>
									<i class="mdi mdi-delete-circle"></i>
								</b-link>
								<div class="product-image">
									<video
									v-if="
									gallary_view_nights.file_extension === 'mp4' ||
									gallary_view_nights.file_extension === 'mkv'
									"
									class="embed-responsive-item"
									controls
									:src="gallary_view_nights.attachment"
									:type="'video/' + gallary_view_nights.file_extension"
									></video>
									<img
									:src="gallary_view_nights.attachment"
									v-if="
									gallary_view_nights.file_extension === 'png' ||
									gallary_view_nights.file_extension === 'jpeg' ||
									gallary_view_nights.file_extension === 'jpg' ||
									gallary_view_nights.file_extension === 'svg'
									"
									/>
								</div>
								<div class="img-overlay">
									
									<div class="text">
									
									{{ gallary_view_nights.description || " Add description"}}  </div>
									
									
									
									<!-- <i class="mdi mdi-pencil cursor-pointer"></i>  {{ gallary_view_days.description || " Add description"}} -->
								</div>
								<b-popover 
							:target="`popover-reactive1-${index}`"
							triggers="click"
							placement="bottom"
							ref="popover"
							id="my-trigger-button-id"
							no-fade
							>
								<template #title>
									<b-button class="close" aria-label="Close" @click="onClose3()">
										<span class="d-inline-block" aria-hidden="true">&times;</span>
									</b-button>
									Banner Text update
								</template>
								
							<div> 
							<textarea :id="`bannar-${index}`" class="form-control venue-custom-input" :value="gallary_view_nights.description" ></textarea>
							</div>
							<div class="mt-3">
									
								<b-button @click="onClose3()" size="sm" variant="danger" class="mr-2">Cancel</b-button>
									<b-button  size="sm" variant="primary"  @click="saveGallerydesc($event,gallary_view_nights.id, 'bannar',index)">
									Save</b-button>
								</div>
							</b-popover>

							</div>
						</b-col>
					</b-row>
					<!--<carousel
						v-if="gallary_view_night && gallary_view_night.length"
						class="slider_event"
						:nav="true"
						:items="3"
						:dots="false"
						:mouseDrag="true"
						:touchDrag="true"
						:smartSpeed="800"
						:margin="10"
						:responsive="{
						0: { items: 1 },
						575: { items: 2 },
						991: { items: 3 },
						1199: { items: 3 },
						}"
						:key="day_night"
						>
						<div
						v-for="(gallary_view_nights, index) in gallary_view_night"
						:key="index"
						>
						<div class="product-box">
						<b-link
						class="delete-btn"
						@click="remove_images('day_night', index)"
						>
						<i class="mdi mdi-delete-circle"></i>
						</b-link>
						<div class="product-image">
						<video
						v-if="
						gallary_view_nights.file_extension === 'mp4' ||
						gallary_view_nights.file_extension === 'mkv'
						"
						class="embed-responsive-item"
						controls
						:src="gallary_view_nights.attachment"
						:type="'video/' + gallary_view_nights.file_extension"
						></video>
						<img
						:src="gallary_view_nights.attachment"
						v-if="
						gallary_view_nights.file_extension === 'png' ||
						gallary_view_nights.file_extension === 'jpeg' ||
						gallary_view_nights.file_extension === 'jpg' ||
						gallary_view_nights.file_extension === 'svg'
						"
						/>
						</div>
						</div>
						</div>
					</carousel>-->
					<div class="no-data" v-if="gallary_view_night.length === 0">
						<p>No images and video are here.</p>
					</div>
				</b-tab>
				<b-tab title="Images  " class="mt-3">
					
					<b-row>
						<b-col cols="3" v-for="(gallary_view_fronts, index) in gallary_view_front"
						:key="index">
							<div class="product-box">
								<b-link  :id="`popover-reactive2-${index}`"
								class="edit-btn">
									<i class="mdi mdi-pencil"></i>
								</b-link>
								<b-link
								class="delete-btn"
								@click="remove_images('front_view', index)"
								>
									<i class="mdi mdi-delete-circle"></i>
								</b-link>
								<div class="product-image">
									<video
									v-if="
									gallary_view_fronts.file_extension === 'mp4' ||
									gallary_view_fronts.file_extension === 'mkv'
									"
									class="embed-responsive-item"
									controls
									:src="gallary_view_fronts.attachment"
									:type="'video/' + gallary_view_fronts.file_extension"
									></video>
									<img
									:src="gallary_view_fronts.attachment"
									v-if="
									gallary_view_fronts.file_extension === 'png' ||
									gallary_view_fronts.file_extension === 'jpeg' ||
									gallary_view_fronts.file_extension === 'jpg' ||
									gallary_view_fronts.file_extension === 'svg'
									"
									/>
								</div>
								<div class="img-overlay">
									
									<div class="text">
										{{ gallary_view_fronts.description || " Add description"}}
										
									</div>
									
								</div>
								<b-popover 
							:target="`popover-reactive2-${index}`"
							triggers="click"
							placement="bottom"
							ref="popover"
							id="my-trigger-button-id"
							no-fade
							>
								<template #title>
									<b-button class="close" aria-label="Close" @click="onClose3()">
										<span class="d-inline-block" aria-hidden="true">&times;</span>
									</b-button>
									photos Text update
								</template>
								
							<div> 
							<textarea :id="`photos-${index}`" class="form-control venue-custom-input" :value="gallary_view_fronts.description" ></textarea>
							</div>
							<div class="mt-3">
									
								<b-button @click="onClose3()" size="sm" variant="danger" class="mr-2">Cancel</b-button>
									<b-button  size="sm" variant="primary"  @click="saveGallerydesc($event,gallary_view_fronts.id, 'photos',index)">
									Save</b-button>
								</div>
							</b-popover>
							</div>
						</b-col>
					</b-row>
					
					<!--<carousel
						v-if="gallary_view_front && gallary_view_front.length"
						class="slider_event"
						:nav="true"
						:items="3"
						:dots="false"
						:mouseDrag="true"
						:touchDrag="true"
						:smartSpeed="800"
						:margin="10"
						:responsive="{
						0: { items: 1 },
						575: { items: 2 },
						991: { items: 3 },
						1199: { items: 3 },
						}"
						:key="front_view"
						>
						
						<div
						v-for="(gallary_view_fronts, index) in gallary_view_front"
						:key="index"
						>
						<div class="product-box">
						<b-link
						class="delete-btn"
						@click="remove_images('front_view', index)"
						>
						<i class="mdi mdi-delete-circle"></i>
						</b-link>
						<div class="product-image">
						<video
						v-if="
						gallary_view_fronts.file_extension === 'mp4' ||
						gallary_view_fronts.file_extension === 'mkv'
						"
						class="embed-responsive-item"
						controls
						:src="gallary_view_fronts.attachment"
						:type="'video/' + gallary_view_fronts.file_extension"
						></video>
						<img
						:src="gallary_view_fronts.attachment"
						v-if="
						gallary_view_fronts.file_extension === 'png' ||
						gallary_view_fronts.file_extension === 'jpeg' ||
						gallary_view_fronts.file_extension === 'jpg' ||
						gallary_view_fronts.file_extension === 'svg'
						"
						/>
						</div>
						<div class="img-overlay">
						<div class="text">
						<input type="text">
						</div>
						</div>
						</div>
						</div>
					</carousel>-->
					<div class="no-data" v-if="gallary_view_front.length === 0">
						<p>No images and video are here.</p>
					</div>
				</b-tab>
				<b-tab title="videos" class="mt-3">
					<b-row>
						<b-col cols="3" v-for="(gallary_view_backs, index) in gallary_view_back"
						:key="index">
							<div class="product-box">
								<!-- <b-link  :id="`popover-reactive2-${index}`"
								class="edit-btn">
									<i class="mdi mdi-pencil"></i>
								</b-link> -->
								<b-link
								class="delete-btn"
								@click="remove_images('front_view', index)"
								>
									<i class="mdi mdi-delete-circle"></i>
								</b-link>
								<div class="product-image">
									<video
									v-if="
									gallary_view_backs.file_extension === 'mp4' ||
									gallary_view_backs.file_extension === 'mkv'
									"
									class="embed-responsive-item"
									controls
									:src="gallary_view_backs.attachment"
									:type="'video/' + gallary_view_backs.file_extension"
									></video>
									
								</div>
								<div class="img-overlays">
									<i class="mdi mdi-pencil editoption"></i>
									<div class="text">
									</div>
								</div>
							</div>
						</b-col>
					</b-row>


					<!--<carousel
					v-if="gallary_view_back && gallary_view_back.length"
					class="slider_event"
					:nav="true"
					:items="3"
					:dots="false"
					:mouseDrag="true"
					:touchDrag="true"
					:smartSpeed="800"
					:margin="10"
					:responsive="{
					0: { items: 1 },
					575: { items: 2 },
					991: { items: 3 },
					1199: { items: 3 },
					}"
					:key="back_view"
					>
						<div
						v-for="(gallary_view_backs, index) in gallary_view_back"
						:key="index"
						>
							<div class="product-box">
								<b-link
								class="delete-btn"
								@click="remove_images('back_view', index)"
								>
									<i class="mdi mdi-delete-circle"></i>
								</b-link>
								<div class="product-image">
									<video
									v-if="
									gallary_view_backs.file_extension === 'mp4' ||
									gallary_view_backs.file_extension === 'mkv'
									"
									class="embed-responsive-item"
									controls
									:src="gallary_view_backs.attachment"
									:type="'video/' + gallary_view_backs.file_extension"
									></video>
									<img
									:src="gallary_view_backs.attachment"
									v-if="
									gallary_view_backs.file_extension === 'png' ||
									gallary_view_backs.file_extension === 'jpeg' ||
									gallary_view_backs.file_extension === 'jpg' ||
									gallary_view_backs.file_extension === 'svg'
									"
									/>
								</div>
							</div>
						</div>
					</carousel>-->
					<div class="no-data" v-if="gallary_view_back.length === 0">
						<p>No video are here.</p>
					</div>
				</b-tab>
			</b-tabs>
			
		</div>
		
		<b-modal
		:no-close-on-backdrop="true"
		title="Add images/videos for gallary view"
		ok-title="Save"
		@ok="saveGallery"
		@cancel="closeGallery"
		id="gallary_view"
		size = "xl"
		header-bg-variant="primary" 
		header-text-variant="light"
		>
			<b-row>
				<b-col
				class="d-flex align-items-stretch"
				cols="12"
				lg="6"
				md="12"
				sm="12"
				>
					<b-card class="mb-4 w-100" border-variant="primary">
						<div class="text-center">
							<h4 class="font-weight-light">
							Thumbnail Photos </h4>
							<b-form-file
							accept=".jpg, .png, .jpeg, .svg"
							multiple
							:file-name-formatter="file_day_light"
							v-model="files_day_light"
							@change="onThemeChange"
							></b-form-file>
						</div>
						<span v-if="meta && meta.size < 1024 * 1024 "> </span>
						<span class="text-danger" v-else> Sorry! file is large  </span>
						<ul class="mt-2">
							<li>Thumbnail Image	Should be 150 x 150 pixels	Image Aspect Ratio 1:1</li>
							<li>Max size is 2MB</li>
							<li>Allowed Only 5 images</li>
						</ul>
					</b-card>
				</b-col>
				<b-col
				class="d-flex align-items-stretch"
				cols="12"
				lg="6"
				md="12"
				sm="12"
				>
					<b-card class="mb-4 w-100">
						<div class="text-center">
							<h4 class="font-weight-light">
								Upload Photos 
							</h4>
							<b-form-file
							accept=".jpg, .png, .jpeg, .svg"
							multiple
							:file-name-formatter="file_front_view"
							v-model="files_front_view"
							@change="onThemeChange1"
							></b-form-file>
							<span v-if="meta1 && meta1.size < 1024 * 1024 "> </span>
							<span class="text-danger" v-else> Sorry! file is large  </span>
							<ul class="mt-2">
								<li>Image Max size is 2MB</li>
								<li>Allowed Only 10 images</li>
							</ul>
						</div>
					</b-card>
				</b-col>
			</b-row>
			<b-row>
				<b-col
				class="d-flex align-items-stretch"
				cols="12"
				lg="6"
				md="12"
				sm="12"
				>
					<b-card class="mb-4 w-100">
						<div class="text-center">
							<h4 class="font-weight-light">
								Upload banner
							</h4>
							<b-form-file
							accept=".jpg, .png, .jpeg, .svg"
							multiple
							:file-name-formatter="file_night_life"
							v-model="files_night_life"
							@change="onThemeChange2"
							></b-form-file>
							<span v-if="meta2 && meta2.size < 1024 * 1024 "> </span>
							<span class="text-danger" v-else> Sorry! file is large  </span>
							<ul class="mt-2">
								<li>Image Max size is 2MB</li>
								<li>Allowed Only 10 images</li>
							</ul>
						</div>
						
					</b-card>
				</b-col>
				<b-col
				class="d-flex align-items-stretch"
				cols="12"
				lg="6"
				md="12"
				sm="12"
				>
					<b-card class="mb-4 w-100">
						<div class="text-center">
							<h4 class="font-weight-light">
								Videos
							</h4>
							<b-form-file
							accept=".mp4, .mkv"
							multiple
							:file-name-formatter="file_back_view"
							v-model="files_back_view"
							@change="onThemeChange3"
							></b-form-file>
							<span v-if="meta3 && meta3.size < 1024 * 1024 "> </span>
							<span class="text-danger" v-else> Sorry! file is large  </span>
							<ul class="mt-2">
								<li>Image Max size is 10MB</li>
								<li>Allowed Only 2 Videos</li>
							</ul>
						</div>
					</b-card>
				</b-col>
			</b-row>
		</b-modal>
		
	</div>
</template>

<script>
	import axios from "axios";
	export default {
		props:['chld_venue_id'],
		data() {
			return {
				files_day_light: [],
				files_front_view: [],
				files_night_life: [],
				files_back_view: [],
				file_day_light:'',
				file_front_view:'',
				file_night_life:'',
				file_back_view:'',
        gallary_view_data: [],
			gallary_view_day: [],
			gallary_view_night: [],
			gallary_view_front: [],
			gallary_view_back: [],
      gallerydesc:{},
      meta: null,
			meta1: null,
			meta2: null,
			meta3: null,
			}
		},
		methods:
		{
			onThemeChange(event) {
				const file = event.target.files[0];
				
				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = (evt) => {
					let img = new Image();
					const meta = (img.onload = () => {
						return {
							size: event.target.files[0].size,
							width: img.width,
							height: img.height,
						};
					});
					img.src = evt.target.result;
					this.meta = meta();
				};
			},
			onThemeChange1(event) {
				const file = event.target.files[0];
				
				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = (evt) => {
					let img = new Image();
					const meta1 = (img.onload = () => {
						return {
							size: event.target.files[0].size,
							width: img.width,
							height: img.height,
						};
					});
					img.src = evt.target.result;
					this.meta1 = meta1();
				};
			},
			onThemeChange2(event) {
				const file = event.target.files[0];
				
				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = (evt) => {
					let img = new Image();
					const meta2 = (img.onload = () => {
						return {
							size: event.target.files[0].size,
							width: img.width,
							height: img.height,
						};
					});
					img.src = evt.target.result;
					this.meta2 = meta2();
				};
			},
			onThemeChange3(event) {
				const file = event.target.files[0];
				
				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = (evt) => {
					let img = new Image();
					const meta3 = (img.onload = () => {
						return {
							size: event.target.files[0].size,
							width: img.width,
							height: img.height,
						};
					});
					img.src = evt.target.result;
					this.meta3 = meta3();
				};
			},
			remove_images(type, index) {
				if (type === "day_light") {
					this.delete_gallery_view(type, this.gallary_view_day[index], index);
				}
				if (type === "day_night") {
					this.delete_gallery_view(type, this.gallary_view_night[index], index);
				}
				if (type === "front_view") {
					this.delete_gallery_view(type, this.gallary_view_front[index], index);
				}
				if (type === "back_view") {
					this.delete_gallery_view(type, this.gallary_view_back[index], index);
				}
			},
			delete_gallery_view(type, file_data, index) {
				axios
				.post("/api/delete_gallery_file", {
					child_venue_id: this.$route.params.id,
					image_id: file_data.id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.$root.$refs.app.showToast("success", resp.data.message);
							this.delete_images(type, index);
							} else {
							this.$root.$refs.app.showToast("danger", resp.data.message);
						}
					}
				});
			},
			delete_images(type, index) {
				if (type === "day_light") {
					this.gallary_view_day.splice(index, 1);
					this.day_light++;
				}
				if (type === "day_night") {
					this.gallary_view_night.splice(index, 1);
					this.day_night++;
				}
				if (type === "front_view") {
					this.gallary_view_front.splice(index, 1);
					this.front_view++;
				}
				if (type === "back_view") {
					this.gallary_view_back.splice(index, 1);
					this.back_view++;
				}
			},
			closeGallery() {
				this.files_day_light.length = [];
				this.files_night_life.length = [];
				this.files_front_view.length = [];
				this.files_back_view.length = [];
				this.$bvModal.hide("gallary_view");
			},
			add_gallary() {
				this.$bvModal.show("gallary_view");
			},
			saveGallery() {
				
				if (
				this.files_day_light.length != 0 ||
				this.files_night_life.length != 0 ||
				this.files_front_view.length != 0 ||
				this.files_back_view.length != 0
				) {
					const tab_type = "gallery_view";
					var edit_status = true;
					const tabs = JSON.stringify(
					'{"overview":1, "shift": 1, "more_detais":1, "gallery":1 , "amenities":0, "add_on":0}'
					);
					var formData = new FormData();
					formData.append("tab_type", tab_type);
					formData.append("tabs", tabs);
					formData.append("child_venue_id", this.$route.params.id);
					formData.append("edit_status", edit_status);
					
					if (this.files_day_light.length != 0) {
						for (var i = 0; i < this.files_day_light.length; i++) {
							formData.append("day_arr[]", this.files_day_light[i]);
						}
					}
					
					if (this.files_night_life.length != 0) {
						for (var a = 0; a < this.files_night_life.length; a++) {
							formData.append("night_arr[]", this.files_night_life[a]);
						}
					}
					if (this.files_front_view.length != 0) {
						for (var n = 0; n < this.files_front_view.length; n++) {
							formData.append("front_arr[]", this.files_front_view[n]);
						}
					}
					if (this.files_back_view.length != 0) {
						for (var b = 0; b < this.files_back_view.length; b++) {
							formData.append("back_arr[]", this.files_back_view[b]);
						}
					}
					axios
					.post("/api/add_child_venue", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then((resp) => {
						if (resp.data.status_code == 200) {
							if (resp.data.status) {
								this.get_gallery_data();
								this.closeGallery();
								} else {
								this.$root.$refs.app.showToast(
								"danger",
								"only .jpg, .png, .jpeg, .svg, .mp4, .mkv file format are supported"
								);
							}
						}
					});
				}
			},
      get_gallery_data() {
			const tab_type = "gallery_view";
			axios
			.post("/api/fetch_details", {
				tab_type: tab_type,
				child_venue_id: this.$route.params.id,
			})
			.then((resp) => {
				if (resp.data.status_code == 200) {
					if (resp.data.status) {
						var path = resp.data.path;
						this.gallary_view_data = resp.data.gallery_view_details;
						this.gallary_view_day = [];
						this.gallary_view_night = [];
						this.gallary_view_front = [];
						this.gallary_view_back = [];
						
						for (var a = 0; a < this.gallary_view_data.length; a++) {
							this.gallary_view_data[a].attachment =
							path + this.gallary_view_data[a].attachment;
							if (this.gallary_view_data[a].image_type === "1") {
								this.gallary_view_day.push(this.gallary_view_data[a]);
							}
							if (this.gallary_view_data[a].image_type === "2") {
								this.gallary_view_night.push(this.gallary_view_data[a]);
							}
							if (this.gallary_view_data[a].image_type === "3") {
								this.gallary_view_front.push(this.gallary_view_data[a]);
							}
							if (this.gallary_view_data[a].image_type === "4") {
								this.gallary_view_back.push(this.gallary_view_data[a]);
							}
						}
						this.day_light++;
						this.day_night++;
						this.front_view++;
						this.back_view++;
					}
				}
			});
		},
    onClose3()
			{
				this.$root.$emit('bv::hide::popover')
			},
			saveGallerydesc(event,id,index,i)
			{
				const value_to_be = document.getElementById(index+"-"+i).value
				event, index,i		,id
                
				axios
			.post("/api/save_gallery_text", {
				val: value_to_be,
				gallery_id: id,
			})
			.then((resp) => { 
				resp;
				this.$root.$emit('bv::hide::popover')
				this.get_gallery_data();
			});

			}
		
		},
		mounted() {
			
			this.get_gallery_data();
			
		},
	}
	
	
	
	
</script>

<style>
	
.img-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color :rgb(112, 103, 103);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
  opacity: 0.8;
	border-bottom-left-radius:16px;
	border-bottom-right-radius:16px;
}

.product-box:hover .img-overlay {
  height: 30%;
}

.img-overlay .text {
  position: absolute;
  top: 30%;
  left: 40%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: left;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
}
.img-overlay  .text span{
  font-weight: 300;
}
.editoption
{
	top: 0;
    position: absolute;
    /* top: -37%; */
    z-index: 222;
    /* background: red; */
    color: white;
    cursor: pointer;
}
.btn-extra
{
	position: absolute;
    right: 21px;
}
.btn-extras
{
	position: absolute;
    right: -50px;
}
.img-overlay .text textarea {
	background: unset;
	border: none;
	border-bottom:1px solid white;
	color:white;
}
.custom-fixed-top {
    position: fixed !important;
    top: 68px;
   
    z-index: 1030;
    width: -webkit-fill-available;
    background: #f3f2ee;
	}
  .event-tabs .tabs div ul{
    border-bottom: 2px solid #DD1BFB !important;
}
.product-box a.edit-btn {
	position: absolute;
    right: 57px;
    top: 20px;
    z-index: 9;
    background: #A44BF3;
    color: #fff;
    width: 27px;
    height: 28px;
    border-radius: 100%;
    text-align: center;
    line-height: 31px;
    font-size: 21px;
}
</style>